import React, { useEffect } from 'react'
import Layout from '../components/layout'
import '../styles/mentions-legales.scss'
import Seo from '../components/seo'

export default function MentionsLegales() {

    useEffect(() => {
        onmousemove = (ev) => {
            const cursor = document.querySelector('#id_cursor')
            const cursorPoint = document.querySelector('#id_cursor_point')

            cursor.style.top = `${ev.pageY}px`
            cursor.style.left = `${ev.pageX}px`
            cursorPoint.style.top = `${ev.pageY}px`
            cursorPoint.style.left = `${ev.pageX}px`
        }
    }, [])

    return (
        <Layout>
            <Seo title="Mentions Légales" />
            <span id="id_cursor" className="cursor" />
            <span id="id_cursor_point" />

            <div className="modules page_mentions_legales">

                <section className="module_2 container">
                    <h3>Responsable de publication</h3>
                    <p><b>Responsable :</b> Benjamin Baudin</p>
                    <p><b>Nom commercial :</b> BBWEBA / Pixel Développement</p>
                    <p><b>Statut juridique :</b> Auto-Entrepreneur</p>
                    <p><b>Adresse :</b> 111 allée belle vallée - 73200 - Gilly sur Isère</p>
                    <p><b>N° SIRET :</b> 841.176.993.00019</p>
                    <p><b>Code APE :</b> 6201Z Programmation informatique</p>

                    <h3>Conception et hebergement</h3>
                    <p><b>Conception :</b> Pixel Développement, siège social au <i>111 allée belle vallée - 73200 - Gilly sur Isère</i></p>
                    <p><b>Nom de domaine :</b> OVH SAS, siège social au <i>2 rue Kellermann - 59100 Roubaix - France</i></p>
                    <p><b>Hébergement :</b> Netlify, siège social au <i>2325 3rd Street, Suite 215, San Francisco, California 94107</i></p>

                    <h3>Condition Générales d'Utilisation</h3>
                    <h4>Cookies</h4>
                    <p>Les informations obtenues par le biais du formulaire de contact, des cookies et de l’outil d’analyse de trafic sont destinées au seul usage de Benjamin Baudin. Ces informations sont réservées à un usage d’analyse et ne pourront être vendues ou mises à la disposition de tierces parties.
                        Les données enregistrés par l'utilisateur lors de l'inscription à la newsletter seront gardées jusqu'a ce que l'utilisateur se désabonne via le lien de désabonnement situé en bas de la newsletter.
                        Granito des Savoie se réserve le droit de modifier, à tout moment et sans préavis, les présentes CGU afin de les adapter aux évolutions du Site et/ou de son exploitation. Un email sera envoyé pour prévenir les utilisateurs enregistrés des nouvelles CGU.</p>
                    <h4>Déclaration de la CNIL</h4>
                    <p>En application de la loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, vous disposez des droits d’opposition (art. 26), d’accès (art. 34 à 38) et de rectification (art. 36) des données vous concernant. Pour l’exercer, vous pouvez faire votre demande à Benjamin Baudin, responsable de publication du site www.electricien-savoie-73.fr via le formulaire de contact de ce site.</p>
                </section>
            </div>

        </Layout>
    )
}